// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-demostracio-ca-js": () => import("./../src/pages/demostracio.ca.js" /* webpackChunkName: "component---src-pages-demostracio-ca-js" */),
  "component---src-pages-demostracion-js": () => import("./../src/pages/demostracion.js" /* webpackChunkName: "component---src-pages-demostracion-js" */),
  "component---src-pages-fail-ca-js": () => import("./../src/pages/fail.ca.js" /* webpackChunkName: "component---src-pages-fail-ca-js" */),
  "component---src-pages-fail-js": () => import("./../src/pages/fail.js" /* webpackChunkName: "component---src-pages-fail-js" */),
  "component---src-pages-gracias-js": () => import("./../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-gracies-ca-js": () => import("./../src/pages/gracies.ca.js" /* webpackChunkName: "component---src-pages-gracies-ca-js" */),
  "component---src-pages-index-ca-js": () => import("./../src/pages/index.ca.js" /* webpackChunkName: "component---src-pages-index-ca-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nota-legal-js": () => import("./../src/pages/nota-legal.js" /* webpackChunkName: "component---src-pages-nota-legal-js" */),
  "component---src-pages-registre-ca-js": () => import("./../src/pages/registre.ca.js" /* webpackChunkName: "component---src-pages-registre-ca-js" */),
  "component---src-pages-registro-js": () => import("./../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */)
}

